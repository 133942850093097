<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card max-width="800" class="mx-auto">
          <v-toolbar class="grey lighten-5 toolbar-custom" elevation="0"
                     style="background-color: #f7f7f7 !important;
                     border-bottom: 2px solid #e8e8e8 !important;">
            <v-toolbar-title style="font-size: 1.3rem">
              Вивантаження податкових накладних в xml
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-2">
            <v-form ref="form" v-model="formValid">
              <v-row>
                <v-col cols="12" md="6">
                  <date-component v-model="document_month"
                                  :class="document_month ? '' : 'req-star'"
                                  label="Місяць нарахування"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select v-model="document_by_pays"
                              :items="by_pays" filled
                              :class="document_by_pays ? '' : 'req-star'"
                              color="grey"
                              label="Тип формування" hide-details
                />
                </v-col>
                <v-col cols="12">
                  <v-select v-model="document_xml_form"
                            :items="xml_forms" filled
                            :class="document_xml_form ? '' : 'req-star'"
                            color="grey"
                            label="Форма xml файла" hide-details
                  />
                </v-col>
                <v-col cols="6">
                  <date-component v-model="document_date_start" label="Дата документа з" :class_="!document_date_start ? 'req-star' : ''"/>
                </v-col>
                <v-col cols="6">
                  <date-component v-model="document_date_end" label="Дата документа по" :class_="!document_date_end ? 'req-star' : ''"/>
                </v-col>
                <v-col cols="12">
                  <v-btn depressed text block color="secondary darken-1" class="button-accept" :disabled="!documentReady()" @click.stop="crud_operation">
                    Виконати
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import legacyFillAPI from '@/utils/axios/legacy_documents'
import {ALERT_SHOW} from "@/store/actions/alert";
import {endOfMonth} from "@/utils/icons";
import {mapGetters} from "vuex";
import {QUESTION_SHOW} from "@/store/actions/question";

const modalDeleteId = 'legacy-export-tax-bill-to-xml'

export default {
  name: "LegacyExportTaxBillToXml",
  components: {
  },
  computed: {
    ...mapGetters({
      current_month: 'getCurrentMonthLegacy',
      modalAnswer: 'question_answer'
    })
  },
  data() {
    return {
      delete_watcher: null,
      formValid: false,
      document_month: null,
      document_by_pays: null,
      document_xml_form: null,
      document_date_start: null,
      document_date_end: null,
      xml_forms: [
        {text: 'J1201015 від 01.08.2023', value: 'J1201015'},
      ],
      by_pays: [
        {text: 'Усі', value: 'ALL'},
        {text: 'По нарахуванні', value: 'BY_CHARGE'},
        {text: 'ПО оплаті', value: 'BY_PAYS'},
      ],
    }
  },
  methods: {
    documentReady() {
      if (!this.document_xml_form) {
        return false
      }

      if (!this.document_month) {
        return false
      }

      if (!this.document_by_pays) {
        return false
      }

      return !(!this.document_date_start || !this.document_date_end);


    },
    crud_operation() {
      if (this.documentReady()) {
        const payload = {
          text: `Підтвердіть формування xml файлів (Файли будь запаковані в архів)`,
          accept_button: true,
          id: modalDeleteId
        }
        this.$store.dispatch(QUESTION_SHOW, payload)

      }
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  const payload = {
                    document_date: this.document_date,
                    document_month: this.document_month,
                    document_by_pays: this.document_by_pays,
                    document_date_start: this.document_date_start,
                    document_date_end: this.document_date_end,
                    document_xml_form: this.document_xml_form
                  }

                  legacyFillAPI.document_tax_bill_export_to_xml(payload)
                      .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a');
                        const file_name = response.headers['x-blob-file-name'] || 'tax_bills_outcome.zip'
                        link.href = url;
                        link.setAttribute('download', file_name); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                      })
                      .catch(err => {
                        const error = err.response.data.detail;
                        this.$store.dispatch(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                      });

                }
              }
            }
          }
      )
    }
  },
  watch: {
    current_month(payload) {
      if (payload) {
        this.document_month = payload
        this.document_date_start = payload
        this.document_date_end = endOfMonth(payload)
      }
    }
  },
  created() {
    this.document_month = this.current_month
    this.document_date_start = this.current_month
    this.document_date_end = endOfMonth(this.current_month)
    this.document_xml_form = (this.xml_forms[this.xml_forms.length - 1] || {}).value || null
    this.document_by_pays = 'ALL'
    this.watch_modal_answer()
  },
  beforeDestroy() {
    if (this.delete_watcher) {
      this.delete_watcher()
    }
  }
}
</script>

<style scoped>

</style>